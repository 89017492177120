// src/components/ExternalLinks.js
import React from 'react';
import './ExternalLinks.css';

function ExternalLinks() {
  return (
    <>
      <div
        className="adsense"
        dangerouslySetInnerHTML={{
          __html: `
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5950615989586230"
             crossorigin="anonymous"></script>
            <ins class="adsbygoogle"
                 style="display:block"
                 data-ad-client="ca-pub-5950615989586230"
                 data-ad-slot="2213108757"
                 data-ad-format="auto"
                 data-full-width-responsive="true"></ins>
            <script>
                 (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
          `,
        }}
      ></div>

      <div className="external-links-container">
        <h1>Additional Resources</h1>
        <p className="intro">
          Explore these valuable resources to grow, monetize, and improve your newsletter. Each link has been selected to provide actionable insights and industry best practices.
        </p>

        <section className="resources-list">
          <div className="resource-section">
            <h2>How to Grow Your Newsletter</h2>
            <ul>
              <li><a href="https://www.thesocialmediahat.com/blog/10-free-ways-to-grow-your-newsletter-subscribers/" target="_blank" rel="noopener noreferrer">10 Free Ways To Grow Your Newsletter Subscribers</a></li>
              <li><a href="https://blog.beehiiv.com/p/how-to-grow-newsletter-subscribers" target="_blank" rel="noopener noreferrer">The ultimate A to Z guide to newsletter subscriber growth</a></li>
              <li><a href="https://www.marketermilk.com/blog/how-to-start-a-newsletter" target="_blank" rel="noopener noreferrer">How to start a newsletter (and grow it)</a></li>
              <li><a href="https://zapier.com/blog/how-to-grow-a-newsletter/" target="_blank" rel="noopener noreferrer">How to grow a newsletter without paid ads</a></li>
            </ul>
          </div>

          <div className="resource-section">
            <h2>How to Monetize Your Newsletter</h2>
            <ul>
              <li><a href="https://www.mailerlite.com/blog/ways-to-get-and-monetize-email-opt-ins-from-your-blog" target="_blank" rel="noopener noreferrer">How to monetize a newsletter: 14 proven ways</a></li>
              <li><a href="https://www.memberspace.com/blog/how-to-monetize-a-newsletter/" target="_blank" rel="noopener noreferrer">How to monetize a newsletter: 23 strategies for creators</a></li>
              <li><a href="https://blog.beehiiv.com/p/20-ways-to-monetize-your-newsletter3" target="_blank" rel="noopener noreferrer">20 ways to monetize your newsletter</a></li>
              <li><a href="https://mailchimp.com/resources/how-to-monetize-a-newsletter/" target="_blank" rel="noopener noreferrer">How to monetize a newsletter in 7 steps</a></li>
            </ul>
          </div>

          <div className="resource-section">
            <h2>How to Sell Your Newsletter</h2>
            <ul>
              <li><a href="https://blog.beehiiv.com/p/sell-email-newsletter-business" target="_blank" rel="noopener noreferrer">How to sell your 7-figure email newsletter</a></li>
              <li><a href="https://flippa.com/blog/how-to-sell-your-newsletter/" target="_blank" rel="noopener noreferrer">How to sell your newsletter: A comprehensive guide</a></li>
            </ul>
          </div>

          <div className="support-link">
            <a href="https://www.paypal.com/donate/?hosted_button_id=JYAPBENGNYQBU" target="_blank" rel="noopener noreferrer">
              Support with a tip
            </a>
          </div>
        </section>
      </div>
    </>
  );
}

export default ExternalLinks;
